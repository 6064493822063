<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeTickets.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onFilterModal">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button
          :router-link="`/tabs/stores/${this.$route.params.id}/tickets/add`"
        >
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="ticket in tickets"
        :key="ticket.id"
        @click="onSelect(ticket)"
        button
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-thumbnail
                v-if="ticket.project && ticket.project.customer.logo"
              >
                <img :src="ticket.project.customer.logo" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-subtitle v-if="ticket.project">
                {{ ticket.project.name }} - {{ ticket.project.customer.name }}
              </ion-card-subtitle>
              <ion-card-title>
                {{ formatDate(ticket.created) }} -
                {{ getScenarioNameWithParent(ticket.scenario, '') }}
              </ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ODNBadges>
            <ODNBadge :color="ticket.ticketStatus.color">
              {{ $t(`ticketStatuses.${ticket.ticketStatus.name}`) }}
            </ODNBadge>
            <ODNBadge v-if="ticket.ticketType" :color="ticket.ticketType.color">
              {{ $t(`ticketTypes.${ticket.ticketType.name}`) }}
            </ODNBadge>
            <ODNBadge>
              <ion-icon :icon="ellipseOutline" />
              {{ $t(`ticketOrigins.${ticket.ticketOrigin.name}`) }}
            </ODNBadge>
          </ODNBadges>
          <p>{{ ticket.contactName }}</p>
          <p>{{ ticket.contactPhone }}</p>
          <p>{{ ticket.contactEmail }}</p>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="tickets.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  IonThumbnail,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  modalController,
  toastController,
} from '@ionic/vue';
import { filterOutline, ellipseOutline, add } from 'ionicons/icons';

import ODNModalStoreTicketsFilters from '@m/odn-modal-store-tickets-filters.vue';
import ODNNoContent from '@c/odn-no-content.vue';
import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

import APIService from '@s/api.service';

export default {
  name: 'StoreTickets',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    IonThumbnail,
    IonRow,
    IonCol,
    IonFab,
    IonFabButton,
    ODNNoContent,
    ODNBadges,
    ODNBadge,
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      tickets: [],
      filters: {
        projects: [],
        sort: 'ticket.created',
        direction: 'desc',
      },
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
      filterOutline,
      ellipseOutline,
      add,
    };
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.tickets = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('tickets', ['selectTicket']),
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get(
          `/stores/${this.$route.params.id}/tickets`,
          {
            page: this.page,
            limit: this.limit,
            search: this.searchText,
            sort: this.filters.sort,
            direction: this.filters.direction,
            projects: this.filters.projects.length
              ? this.filters.projects.map(r => r.id).join(',')
              : null,
          },
        );

        if (clear) {
          this.tickets = [];
        }

        this.tickets = this.tickets.concat(response.data.items);

        if (this.tickets.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.tickets.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    async onFilterModal() {
      const modal = await modalController.create({
        component: ODNModalStoreTicketsFilters,
        componentProps: {
          filters: this.filters,
        },
      });
      await modal.present();

      // wait for a response when closing the modal
      const newFilters = (await modal.onDidDismiss()).data;

      if (newFilters) {
        this.filters = newFilters;
        this.fetchData(true);
      }
    },
    onSelect(ticket) {
      this.selectTicket(ticket);
      this.$router.push(
        `/tabs/stores/${this.$route.params.id}/tickets/${ticket.id}`,
      );
    },
    getScenarioNameWithParent(scenario, completeScenario) {
      if (completeScenario) {
        completeScenario = scenario.name + ' > ' + completeScenario;
      } else {
        completeScenario = scenario.name;
      }
      if (scenario.parent) {
        return this.getScenarioNameWithParent(
          scenario.parent,
          completeScenario,
        );
      } else {
        return completeScenario;
      }
    },
  },
};
</script>
